.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.bg-mainColor {
  background-color: #27302b;
}
.supportingColor {
  background-color: #505050;
}
.mainColor {
  color: #fff;
}
.secondaryColor {
  color: #505050;
}
* {
  scrollbar-color: #996633;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  background-color: #27302b;
  width: 9px;
}

*::-webkit-scrollbar-track {
  width: 9px;
  border-left: solid 0.5px #996633;
}

*::-webkit-scrollbar-thumb {
  background-color: #996633;
  border-radius: 20px;
}

.clickableLinkToDemo {
  cursor: pointer;
}
.clickableLinkToDemo:hover {
  font-weight: 900;
  text-decoration: underline;
  text-decoration-color: #fff;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  scrollbar-color: #27302b;
  scrollbar-width: thin;
}

*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #27302b;
  width: 12px;
  border-left: solid 1px #000;
}

*::-webkit-scrollbar-thumb {
  background-color: #996633;
  border-radius: 20px;
}
html {
  scroll-behavior: smooth;
}

.front {
  z-index: 9999;
}
body {
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
}
body.dark {
  background: #101010;
  color: #fff;
}
body.dark-blue {
  background: #07153a;
  color: #fff;
}
body.dark-discord {
  background: rgb(46, 49, 54);
  color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6,
.navbar-brand,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.navbar-brand {
  font-family: "Sulphur Point", sans-serif;
  font-weight: 900 !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.btn.purpleGradient:hover {
  background-position: 100px;
}
.rounded-4 {
  border-radius: 1rem !important;
}
.bg-dark {
  background: #211a2a !important;
}

.py-6 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.container.small-width {
  max-width: 900px;
}
.border-left-dotted {
  border-left: dashed 1px #fff;
  position: relative;
}
.border-left-dotted:before {
  content: " ";
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background: #fff;
  display: block;
  position: absolute;
  left: -5px;
  top: -5px;
}
.border-left-dotted.last:after {
  content: " ";
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background: #fff;
  display: block;
  position: absolute;
  top: auto;
  left: -5px;
  bottom: -5px;
}
py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
.done {
  position: relative;
  overflow: hidden;
}
.done:before {
  content: "Done";
  display: block;
  position: absolute;
  right: -50px;
  top: -5px;
  padding: 20px 50px 10px 50px;
  transform: rotate(45deg);
  background: #0dcaf0;
}

.border-dark {
  border-color: #333 !important;
}
body.dark-blue .border-dark {
  border-color: #0e1e46 !important;
}
.bg-dark {
  background: #000 !important;
}
body.dark-blue .bg-dark {
  background: #050f29 !important;
}

body.dark-discord .bg-dark {
  background: rgba(31, 34, 37, 1) !important;
}
body.dark-discord .border-dark {
  background: rgba(255, 255, 255, 0.05) rgba(255, 255, 255, 0.05) !important;
}

html {
  scroll-behavior: smooth;
}
.overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: center center;
  z-index: 999;
  pointer-events: none;
  opacity: 0.1;
}
.front {
  z-index: 9999;
}
body {
  font-family: "Urbanist", sans-serif;
  font-weight: 400;
}
body.dark {
  background: #101010;
  color: #fff;
}
body.dark-blue {
  background: -webkit-linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
  background: -moz-linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
  background: -o-linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
  background: linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
  color: #fff;
}
body.dark-discord {
  background: rgb(46, 49, 54);
  color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6,
.navbar-brand,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.navbar-brand {
  font-family: "Sulphur Point", sans-serif;
  font-weight: 900 !important;
}
.semi-bold {
  font-weight: 600 !important;
}
.purpleBackground {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media screen and (max-width: 992px) {
  .purpleBackground {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.btn.purpleGradient:hover {
  background-position: 100px;
}
.rounded-4 {
  border-radius: 1rem !important;
}
.bg-dark {
  background: #211a2a !important;
}
.grey {
  color: #818287 !important;
}
.bg-blur {
  backdrop-filter: blur(2px) !important;
  opacity: 0.8;
}
.anvilImage {
  border-radius: 12px;
  margin-bottom: 20px;
  background: url("images/update/Anvil.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* .tahiImage {
  border-radius: 12px;
  margin-bottom: 20px;
  background: url("images/tahi.jpeg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
.awanaImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/update/Awana.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* .ruaImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/rua2.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
.blindBayImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/update/BlindBay.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
/* .toruImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/toru2.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
} */
.clarisImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/update/Claris.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.fitzroyImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/update/Fitzroy.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.medlandsImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/update/Medlands.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.schoonerBayImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/update/SchoonerBay.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.tryphenaImage {
  border-radius: 12px;
  margin-bottom: 20px;

  background: url("images/update/Tryphena.jpg") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.mainImageBackground {
  background: url("images/dw_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.secondImageBackground {
  background: url("images/about.png") no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.cmImageBackground {
  background: url("images/ram3.jpeg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.bg-dark-blue {
  background: -webkit-linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
  background: -moz-linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
  background: -o-linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
  background: linear-gradient(rgba(7, 21, 58, 1), rgb(22 51 129));
}
.bg-white-blue {
  background: -webkit-linear-gradient(#ffffff, rgba(68, 160, 255, 1));
  background: -moz-linear-gradient(
    rgba(68, 160, 255, 0),
    rgba(68, 160, 255, 1)
  );
  background: -o-linear-gradient(rgba(68, 160, 255, 0), rgba(68, 160, 255, 1));
  background: linear-gradient(rgba(68, 160, 255, 0), rgba(68, 160, 255, 1));
}
.bg-browngreen {
  background-color: #27302b;
}
.bg-mainColor {
  background-color: #27302b;
}
.supportingColor {
  background-color: #505050;
}
.text-LTBlue {
  color: rgba(68, 160, 255, 1) !important;
}
.bg-gb {
  background: rgba(68, 160, 255, 1);
}

.bg-img {
  background-size: 100% auto;
  background-repeat: none;
  background-position: center center;
  background-attachment: fixed;
}
.py-6 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.container.small-width {
  max-width: 900px;
}
.border-left-dotted {
  border-left: dashed 1px #fff;
  position: relative;
}
.border-left-dotted:before {
  content: " ";
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background: #fff;
  display: block;
  position: absolute;
  left: -5px;
  top: -5px;
}
.border-left-dotted.last:after {
  content: " ";
  transform: rotate(45deg);
  width: 10px;
  height: 10px;
  background: #fff;
  display: block;
  position: absolute;
  top: auto;
  left: -5px;
  bottom: -5px;
}
py-6 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.navbar-toggler {
  border: 0px;
}

.done {
  position: relative;
  overflow: hidden;
}
.done:before {
  content: "Done";
  color: white;
  display: block;
  position: absolute;
  right: -50px;
  top: -5px;
  padding: 20px 50px 10px 50px;
  transform: rotate(45deg);
  background: #0dcaf0;
}

.border-dark {
  border-color: #333 !important;
}
body.dark-blue .border-dark {
  border-color: rgb(22 51 129) !important;
}
.bg-dark {
  background: #000 !important;
}
body.dark-blue .bg-dark {
  background: #050f29 !important;
}

body.dark-discord .bg-dark {
  background: rgba(31, 34, 37, 1) !important;
}
body.dark-discord .border-dark {
  background: rgba(255, 255, 255, 0.05) rgba(255, 255, 255, 0.05) !important;
}

.ecobg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
@media screen and (max-width: 800px) {
  .ecobg {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.border-white {
  border: solid 1px #fff;
}
.text-green {
  color: rgba(143, 243, 71, 1);
}
.progress-lg {
  height: 3rem;
}
#roadmap-btn {
  margin-right: 10px;
}
.fadedshort {
  -webkit-animation: fadeinout 1s linear forwards;
  animation: fadeinout 1s linear forwards;
}
.homeImage {
  position: absolute;
}
.faded {
  -webkit-animation: fadein 1s linear forwards;
  animation: fadein 1s linear forwards;
}
.persistOpacity {
  opacity: 0.95 !important;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.fadedshort {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadein {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fadein {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeinDelay {
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeinDelay {
  /* Firefox */
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeinDelay {
  /* Safari and Chrome */
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeinDelay {
  /* Opera */
  from {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.book {
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  text-decoration: none;
}

.mainButton {
  width: 180px !important;
  cursor: pointer;
  background-color: #996633;
  border: 1px solid #fff;
  padding: 20px;
  color: #fff;
  font-size: 18px;
}
.mainButtonOuter {
  cursor: pointer;
  padding: 20px;
  background-color: transparent !important;
}

.mainButton:hover {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  cursor: pointer;
  background-color: #b3875c !important;
  border: 1px solid #fff;
  padding: 20px;
  color: #fff;
  font-size: 18px !important;
}
.mainButtonOuter {
  width: 200px !important;
  cursor: pointer;
  padding: 10px;
  background-color: #996633;
}
.mobileOpaqueTextBox{
  z-index: 9999;
  padding:0px !important;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 0px !important;
}
.mobileOpaqueButton{
  margin-left: 10px;
  padding: 12px !important;
  text-align: center;
  max-width: 100px !important;
  background-color: grey !important;
}
.altButtonOuter {
  width: 200px !important;
  cursor: pointer;
  padding: 10px;
  background-color: transparent;
}
.altButton {
  border: 1px solid #fff;
  background-color: transparent;
  padding: 20px;
  cursor: pointer;
  font-size: 18px;
  min-width: 150px !important;
  white-space: nowrap;
}
.informationMobileButton{
  margin-right: 0px !important;
  min-width: 300px !important;
}
.schoonerBayTextAdjust{
  min-width: 200px !important;
}
.planSelected{
  border: 1px solid rgb(181, 146, 106) !important;
  background-color: #3b3b3b !important;
  padding: 20px;
  cursor: pointer;
  font-size: 18px !important;
}
.altButton:hover {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: 1px solid #fff;
  background-color: #505050 !important;
  padding: 20px;
  cursor: pointer;
  font-size: 18px !important;
}
.secondButton:hover {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  border: 1px solid #fff;
  background-color: #cbcbcb !important;
  padding: 20px;
  cursor: pointer;
  font-size: 18px;
}
.imageNextButton {
  font-size: 72px;
  cursor: pointer;
  color: #ffffff;
  background-color: #7b7b7b;
  border-radius: 12px;
}
.imageNextButton:hover {
  background-color: #404040;
}
.hoverImage {
}
.hoverImage:hover {
  opacity: 0.5 !important;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.hoverImageDarkest {
}
.hoverImageDarkest:hover {
  opacity: 0.2 !important;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.hoverImageNext {
}
.hoverImageNext:hover {
  opacity: 0.8 !important;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.imagePlus {
  position: absolute;
  font-size: 72px;
  right: 25%;
  bottom: 40%;
}
.imagePlus:hover {
  position: absolute !important;
  font-size: 72px !important;
  right: 25% !important;
  visibility: visible !important;
  bottom: 40% !important;
}
.imageLeft {
  position: absolute;
  font-size: 72px;
  right: 47%;
}
.imageRight {
  position: absolute;
  font-size: 72px;
  right: 100px;
}

.imageRight:hover {
  position: absolute;
  font-size: 72px;
  right: 100px !important;
  visibility: visible !important;
  bottom: 45% !important;
}
.imageBottom {
  z-index: 99999 !important;
  position: absolute;
  font-size: 72px;
  right: 45%;
}

.imageBottom:hover {
  position: absolute;
  font-size: 72px;
  right: 12% !important;
  visibility: visible !important;
  bottom: 45% !important;
}
.imageCenter {
  position: absolute;
  font-size: 72px;
  right: 43%;
  bottom: 20%;
}

.imageCenter:hover {
  position: absolute !important;
  font-size: 72px;
  right: 43% !important;
  visibility: visible !important;
  bottom: 20% !important;
}
.imageHoverFont {
  text-align: center;
  font-size: 36px !important;
  color: #fff !important;
}
.imageHoverFontSmall {
  font-size: 20px !important;
  color: #fff !important;
  padding-left: 5px;
  padding-right: 5px;
}
.hide {
  visibility: hidden;
  position: absolute;
  bottom: 25% !important;
}

.myDIV {
}
.myDIV:hover + .hide {
  visibility: visible;
  bottom: 45% !important;
  transition: bottom 0.25s ease;
  position: absolute;
}
.hide + .myDIV:hover {
  bottom: 0px !important;
  transition: bottom 1s ease;
  position: absolute;
}
.myDIV2 {
}
.myDIV2:hover + .hide {
  visibility: visible;
  bottom: 40% !important;
  transition: bottom 0.25s ease;
  position: absolute;
}
.hide + .myDIV2:hover {
  bottom: 0px !important;
  transition: bottom 1s ease;
  position: absolute;
}
.imageMargins {
  padding: 20px !important;
}
.selectedPopupOpaque {
  opacity: 0.5 !important;
}
.portfolioImage2:hover {
  background-color: #505050 !important;
  opacity: 0.9 !important;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
.fadeA {
  animation: animA 10s infinite alternate;
}

@keyframes animA {
  0%,
  25% {
    opacity: 0;
    z-index: 0;
  }
  50% {
    opacity: 0.5;
  }
}

.productRangeHeader {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
}
.tahiSelected {
  background-color: #996633 !important;
  border: 1px solid #fff !important;
  margin: 15px;
  padding: 30px;
  opacity: 1;
}
.ruaSelected {
  background-color: #3f4a44 !important;
  border: 1px solid #505050 !important;
  margin: 15px;
  padding: 30px;
  opacity: 1;
}
.toruSelected {
  background-color: #4f4f4f !important;
  border: 1px solid #b5926a !important;
  margin: 15px;
  padding: 30px;
  opacity: 1;
}
.timelineBox {
  min-width: 340px !important;
  width: 50vw;
  background-color: #505050;
  color: #fff;
  font-size: 24px !important;
  line-height: 40px !important;
}
/* Dropdown Button */
.dropbtn {
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #505050;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  padding: 4px;
  color: #fff;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #353535;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.myNav {
  height: "12vh";
  opacity: 0.95;
  z-index: 9999 !important;
}

.inclusionItem {
  font-size: 20px;
  padding: 10px;
  border-bottom: 1px solid #a4a4a4;
}

.depth {
  box-shadow: 0px 15px 11px 4px rgba(171, 171, 171, 0.24),
    -2px 0px 6px 0px rgba(211, 211, 211, 0.19);
}
.blurTest {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
  width: 100px;
  height: 100px;
  background-color: #505050;
  opacity: 0.8;
}
.blurContent {
  filter: none !important;
  opacity: 1 !important;
}
.inclusionList {
  margin: 20px 0px 20px 20px;
}
.c-center {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  flex-wrap: wrap !important;
  align-content: center !important;
}
.h2Content {
  font-size: 72px;
  font-weight: 100;
  margin-left: 10px;
}
